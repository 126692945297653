import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import {
  i18nDefaultConfig,
  I18N_SUPPORTED_LANGUAGES,
  composeI18nFormatters,
} from '@pm/core/src/localization';

if (import.meta.env.MODE === 'test') {
  throw new Error(
    'i18n should not be imported in test mode. Import directly from "i18next"',
  );
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.VITE_DEBUG_I18N === 'true',
    ...i18nDefaultConfig,
    supportedLngs: [I18N_SUPPORTED_LANGUAGES.en, I18N_SUPPORTED_LANGUAGES.fr],
  });

export default composeI18nFormatters(i18n);
