import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string;
};

const SEO: FunctionComponent<Props> = ({
  title,
  description,
  image = 'Freddie_logo_green.png',
  keywords = 'PrEP, Freddie',
}) => {
  const appName = 'Freddie';
  const imgUrl = `${window.location.origin}/images/${image}`;

  return (
    <Helmet defaultTitle={appName} htmlAttributes={{ lang: 'en' }}>
      <title>{title ?? appName}</title>
      <meta
        name="description"
        content={description ?? 'Freddie - Online PrEP Service'}
      />
      <meta name="keywords" content={keywords} />
      <meta name="image: imgUrl" content={imgUrl} />
      <meta name="og:title" content={title ?? appName} />
      <meta
        name="og:description"
        content={description ?? 'Freddie - Online PrEP Service'}
      />
      <meta name="og:image" content={imgUrl} />
      <meta name="og:type" content="website" />
      <meta name="twitter:creator" content="@GOFREDDlE" />
      <meta
        name="twitter:card"
        content={image ? 'summary_large_image' : 'summary'}
      />
      <meta name="twitter:title" content={title ?? appName} />
      <meta
        name="twitter:description"
        content={description ?? 'Freddie - Online PrEP Service'}
      />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="twitter:alt" content={title ?? appName} />
    </Helmet>
  );
};

export default SEO;
